.use-range {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.use-range__title {
  margin-bottom: 1.4rem;
}

.use-range__items {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: center;
}

.use-range__item {
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  height: 190px;
  justify-content: center;
  padding: 1.2rem;
  text-align: center;
  width: 200px;
}

.use-range__item--active {
  box-shadow: 3px 3px 4px 3px rgba(0, 0, 0, 0.2);
}

.use-range__item--active .use-range__item-label {
  color: var(--color-red);
  font-weight: 700;
}

.use-range__button {
  align-self: baseline;
  margin: 0 auto;
}

.use-range__descriptions {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
  text-align: center;
}

.use-range__description {
  display: none;
  font-size: calc(var(--base-size) * 1.3);
  font-style: italic;
  font-weight: 700;
  line-height: calc(var(--base-size) * 1.6);
  margin: 20px 0;
  max-width: 850px;
}

.use-range__item-icon img {
  height: 7rem !important;
  width: 7rem !important;
}

.use-range__description--active {
  display: block;
}

.use-range-section {
  margin-top: 4rem;
}
