.services-category__items-wrapper,
.services-category__tabs-wrapper {
  display: flex;
  justify-content: center;
}

.services-description__container {
  padding: 1rem;
}

.services-category__tabs,
.services-description__content,
.services__content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.services__group {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: space-between;
  min-width: 320px;
}

@media (width < 500px) {
  .services__group {
    min-width: 100%;
  }

  .services__feature {
    flex-direction: column;
  }

  .services__container {
    padding: 0.1rem;
  }
}

.services-description__headline-wrapper,
.services-description__text-wrapper {
  flex: 1;
}

.services-description__headline {
  margin-bottom: 1rem;
  max-width: 520px;
}

.services-category__container {
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.services-category__items {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.services-category__tab {
  background: 0 0;
  border: none;
  border-radius: 0.4rem;
  font-size: calc(var(--base-size) * 1.4);
  font-weight: 800;
  padding: 0.5rem 3rem;
}

.services-category__tab--active {
  background: #3b3d44;
  color: var(--color-foreground);
}

.services-category__item {
  flex: 1;
}

.services-category__item--link {
  align-items: center;
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  position: relative;
  text-decoration: none;
}

.services-category__title {
  color: var(--color-darkgray);
  display: inline-block;
  font-size: calc(var(--base-size) * 1.25);
  font-weight: 700;
  line-height: calc(var(--base-size) * 1.45);
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.services-category__title::after {
  background-color: currentColor;
  bottom: -0.95rem;
  content: '';
  height: 0.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: width 0.2s ease-in-out;
  width: 0;
}

.services-category__item--link:hover .services-category__title::after {
  width: 95%;
}

.services-category__items--consulting .services-category__title::after {
  background-color: #00aaa1;
}

.services-category__items--offensive .services-category__title::after {
  background-color: #e5443c;
}

.services-category__items--defensive .services-category__title::after {
  background-color: #0089d6;
}

.services-category__icon {
  height: 6.6rem;
  width: unset;
}

@media (width < 500px) {
  .services-category__icon {
    height: 8rem;
  }
}

.services__feature {
  display: flex;
  gap: 0.6rem;
}

.services__feature > img {
  height: 2rem;
  width: 2rem;
}

.services__headline {
  font-size: calc(var(--base-size) * 2);
  font-weight: 700;
  line-height: calc(var(--base-size) * 2.2);
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
}

.services {
  background: #f2efef;
}

.services__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  padding: 4.6rem 3rem;
}

.services__left,
.services__right {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.8rem;
}

.services__checkmark {
  display: flex;
  height: 1rem;
  margin-top: 0.2rem;
}
