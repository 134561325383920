.steps__container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.steps__content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 0.7rem;
  justify-content: center;
}

.steps__step-wrapper {
  background: var(--color-foreground);
  border-radius: 50%;
  box-shadow: 0 7px 14px -10px #5c5c5c;
  padding: 0.4rem;
  position: relative;
}

.steps__arrow {
  left: 23%;
  position: absolute;
  top: -1rem;
  width: 54%;
}

.steps__arrow.flip {
  bottom: -1rem;
  top: unset;
  transform: scaleY(-1);
}

.steps__step {
  align-items: center;
  background: var(--color-foreground);
  border: 0.3rem solid #000;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  height: 180px;
  justify-content: center;
  width: 180px;
}

.main-service[data-service-type='consulting'] .steps__step {
  border-color: var(--color-green);
}

.main-service[data-service-type='defensive'] .steps__step {
  border-color: var(--color-blue);
}

.main-service[data-service-type='offensive'] .steps__step {
  border-color: var(--color-red);
}

.steps__icon-wrapper {
  height: 60px;
  width: 80px;
}

.steps__icon-wrapper img {
  height: 100%;
  width: 100%;
}

.steps__text {
  font-size: calc(var(--base-size) * 0.7);
  font-weight: 700;
  line-height: calc(var(--base-size) * 0.8);
  max-width: 85%;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .steps__step {
    height: 138.6px;
    width: 138.6px;
    height: 158.6px;
    width: 158.6px;
  }

  .steps__icon-wrapper {
    height: 46.2px;
    width: 61.6px;
  }

  .steps__text {
    font-size: calc(var(--base-size) * 0.66);
    line-height: calc(var(--base-size) * 0.77);
  }

  .steps__arrow {
    width: 52.5%;
  }
}
