.contact {
  background: linear-gradient(90deg, #1c1e26 0, #42434a 100%);
  background-image: url('/assets/img/banner-flat.svg');
  background-position: center;
  background-size: cover;
  color: var(--color-foreground);
  padding: calc(var(--base-size) * 3) 0;
  position: relative;
}

.contact::after {
  background: rgba(0, 0, 0, 0.3);
  content: '';
  inset: 0;
  position: absolute;
  z-index: 10;
}

.contact__container {
  display: flex;
  gap: calc(var(--base-size) * 2);
  margin: 0 auto;
  position: relative;
  z-index: 20;
}

.contact__container > div {
  min-width: calc(var(--base-size) * 20);
}

.contact__left {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: calc(var(--base-size) * 1);
}

.contact__headline,
.contact__subheadline {
  font-size: calc(var(--base-size) * 2.5);
  line-height: calc(var(--base-size) * 2.75);
  position: relative;
}

.contact__subheadline {
  margin-bottom: calc(var(--base-size) * 1.8);
}

.contact__headline::before {
  background: var(--color-red);
  content: '';
  height: calc(var(--base-size) * 0.25);
  left: 0;
  position: absolute;
  top: calc(var(--base-size) * -1);
  width: calc(var(--base-size) * 3.6);
}

.contact__features {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-size) * 2);
  margin-top: calc(var(--base-size) * 2);
  margin-top: auto;
  margin-top: 1.2rem;
}

.contact__feature {
  align-items: center;
  display: flex;
  gap: calc(var(--base-size) * 0.6);
}

.contact__checkmark {
  height: calc(var(--base-size) * 2);
  width: calc(var(--base-size) * 2);
}

.contact__feature-text {
  color: #e5e5e5;
  font-size: calc(var(--base-size) * 1.1875);
  font-weight: 500;
}

.contact__right .umbraco-forms-navigation {
  display: flex;
  justify-content: center;
  height: auto;
}

.umbraco-forms-CustomTheme .umbraco-forms-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.umbraco-forms-CustomTheme .umbraco-forms-container {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base-size) * 0.8) calc(var(--base-size) * 0.9);
}

.umbraco-forms-CustomTheme .umbraco-forms-field {
  flex: 1 1 calc(50% - calc(var(--base-size) * 1.4375));
  min-width: calc(var(--base-size) * 11.25);
}

@media (width < 372px) {
  .umbraco-forms-CustomTheme .umbraco-forms-field {
    min-width: 100%;
  }
}

.umbraco-forms-CustomTheme .umbraco-forms-label,
.umbraco-forms-CustomTheme legend {
  display: none;
}

.umbraco-forms-CustomTheme .umbraco-forms-caption {
  color: white;
  display: block;
  font-size: calc(var(--base-size) * 1.6875);
  font-weight: 800;
  margin: 0;
  margin-bottom: calc(var(--base-size) * 2);
  margin-top: calc(var(--base-size) * 1);
  text-align: center;
  white-space: nowrap;
}

.umbraco-forms-CustomTheme .umbraco-forms-fieldset {
  margin-bottom: calc(var(--base-size) * 1.2);
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper {
  align-items: center;
  display: flex;
  width: 100%;
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper input,
.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper select,
.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper textarea {
  border: calc(var(--base-size) * 0.125) solid #dfdcdc;
  border-radius: calc(var(--base-size) * 0.4);
  margin: 0;
  overflow: hidden;
  padding: calc(var(--base-size) * 0.5) calc(var(--base-size) * 0.6) !important;
  resize: none;
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper input,
.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper select,
.umbraco-forms-CustomTheme
  .umbraco-forms-field-wrapper
  textarea
  .umbraco-forms-CustomTheme
  .umbraco-forms-field-wrapper
  ::placeholder {
  color: #878788;
  font-family: 'LiniK Sans', sans-serif;
  font-size: calc(var(--base-size) * 1.125);
  font-weight: 400;
  outline: none;
  width: 100%;
  background: white;
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper ::placeholder,
.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper option {
  font-size: calc(var(--base-size) * 1.125);
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-indent: calc(var(--base-size) * 0.2);
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper input[type='checkbox'] {
  width: unset;
  cursor: pointer;
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper .checkbox * {
  color: white;
}

.umbraco-forms-CustomTheme .umbraco-forms-hidden {
  display: none;
}

.umbraco-forms-CustomTheme .umbraco-forms-submitmessage {
  color: white;
  display: flex;
  font-size: calc(var(--base-size) * 2);
  font-weight: 700;
  justify-content: flex-end;
  margin: auto;
}

.umbraco-forms-CustomTheme .field-validation-error {
  display: none;
}

.contact .umbraco-forms-CustomTheme .umbraco-forms-caption {
  display: none;
}

.contact__right {
  flex: 1;
}

@media (width < 1024px) {
  .contact__container {
    flex-direction: column;
  }

  .contact {
    padding: calc(var(--base-size) * 4) calc(var(--base-size) * 2);
  }
}

.main-service[data-service-type='consulting'] .contact__headline::before {
  background: var(--color-green) !important;
}

.main-service[data-service-type='defensive'] .contact__headline::before {
  background: var(--color-blue) !important;
}

.main-service[data-service-type='offensive'] .contact__headline::before {
  background: var(--color-red) !important;
}

@media (height < 810px) {
  .umbraco-forms-CustomTheme .umbraco-forms-field-wrapper textarea {
    max-height: calc(var(--base-size) * 3.125);
  }

  .umbraco-forms-CustomTheme .umbraco-forms-caption {
    margin-bottom: calc(var(--base-size) * 1);
    margin-left: calc(var(--base-size) * 3);
  }

  .umbraco-forms-CustomTheme .umbraco-forms-field-wrapper *,
  .umbraco-forms-CustomTheme .umbraco-forms-field-wrapper *::placeholder {
    font-size: calc(var(--base-size) * 0.9) !important;
  }
}

@media (height < 810px) {
  .umbraco-forms-CustomTheme {
    max-height: 100%;
    overflow: auto;
  }
}

@media (width < 640px) {
  .umbraco-forms-caption {
    margin-bottom: calc(var(--base-size) * 1.4);
    font-size: calc(var(--base-size) * 2);
  }
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper {
  height: 100%;
  display: flex;
}

.umbraco-forms-CustomTheme .umbraco-forms-field {
  height: 100%;
}

.umbraco-forms-CustomTheme .umbraco-forms-container {
  height: 100%;
}

.longanswer {
}

#c32c5053-a5d7-411f-9439-96598518e540 .row-fluid-custom {
  height: 100%;
}

.umbraco-forms-CustomTheme .umbraco-forms-page,
.umbraco-forms-CustomTheme form,
.umbraco-forms-CustomTheme {
  height: 100%;
}

.umbraco-forms-CustomTheme .umbraco-forms-field-wrapper textarea {
  height: 100%;
  max-height: 100%;
  width: 100%;
}

#c32c5053-a5d7-411f-9439-96598518e540 {
  flex: 1;
}

.umbraco-forms-CustomTheme input,
.umbraco-forms-CustomTheme input::placeholder,
.umbraco-forms-CustomTheme textarea,
.umbraco-forms-CustomTheme textarea::placeholder,
.umbraco-forms-CustomTheme select {
  font-family: 'LiniK Sans', sans-serif !important;
  color: #878788 !important;
}

.umbraco-forms-CustomTheme .button--primary {
color: white !important;
}