.job-offer {
  display: flex;
  gap: 2rem;
  margin-top: 0.6rem;
  padding: 0 2rem;
}

/* .job-offer .iti__flag {
  width: 30px;
  height: 23px;
  margin-top: 0.2rem;
} */

.job-offer .iti__flag {
  margin-top: .3rem;
  margin-left: .5rem;
}

.job-offer .iti input.iti__tel-input,
.job-offer .iti input.iti__tel-input[type='tel'] {
  padding-left: 3.2rem !important;
}

@media (width < 800px) {
  .job-offer {
    flex-direction: column;
  }
}

.job-offer .inner-page-container {
  flex: 1;
  gap: 1.4rem;
}

.job-offer .section-half-split__title:before {
  display: none;
}

.job-offer .job-content-description h4 {
  border-bottom: 0.15rem solid gray;
  color: var(--color-red);
  font-size: calc(var(--base-size) * 1.4);
  margin-bottom: 0.6rem;
  padding-bottom: 0.2rem;
  width: 100%;
}

.job-offer .job-content-description ul {
  line-height: calc(var(--base-size) * 1.2);
}

.job-offer .job-content-description li {
  list-style: disc;
  margin: 1rem 0;
}

.job-offer .section-half-split__title {
  font-size: calc(var(--base-size) * 2.375);
  margin-bottom: 0rem;
}

.job-offer .section-half-split__title::before {
  top: unset !important;
}
