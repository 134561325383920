.share-buttons {
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 99;
  height: 0;
  margin-left: -4.4rem;
  width: 200px;
  width: 45px;
  top: 6rem;
  gap: 1rem;
}

.share-button {
  border-radius: 1.15rem;
  border: 2px solid #1b1e26;
  background: var(--white, #fff);
  width: 2.8rem;
  height: 2.8rem;
  min-height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-button img {
  width: 1.3rem;
  height: 1.1rem;
}
