.content-width-limit {
  max-width: 1480px;
  padding: 0 1rem;
}

@media (width < 1500px) {
  .content-width-limit {
    padding: 0 2rem;
    padding: 0 1rem;

  }
}

@media (width < 900px) {
  .content-width-limit {
    padding: 0 1rem;
  }
}

@media (width < 540px) {
  .content-width-limit {
    padding: 0rem;
    padding: 0 1rem;
  }

  section {
    margin: 2rem 0;
  }
}

.content-width-limit.short {
  max-width: 920px;
  padding: 0 1rem;
}

.umbraco-forms-submitmessage-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
}

.umbraco-forms-submitmessage {
  color: white;
  display: flex;
  font-size: calc(var(--base-size) * 2);
  font-weight: 700;
  justify-content: flex-end;
  line-height: calc(var(--base-size) * 2.2);
  margin: 0;
  text-align: center;
}

.umbraco-forms-submitmessage-image-wrapper {
  align-items: center;
  background: white;
  border-radius: 50%;
  display: flex;
  height: 260px;
  justify-content: center;
  width: 260px;
}

.umbraco-forms-submitmessage-image {
  display: flex;
  height: 160px;
  padding: 2rem;
  width: 160px;
}

.popup-content .umbraco-forms-submitmessage-image {
  padding: 0;
}

.main-service[data-service-type='consulting']
  .umbraco-forms-submitmessage-image-wrapper {
  background: var(--color-green) !important;
}

.main-service[data-service-type='defensive']
  .umbraco-forms-submitmessage-image-wrapper {
  background: var(--color-blue) !important;
}

.main-service[data-service-type='offensive']
  .umbraco-forms-submitmessage-image-wrapper {
  background: var(--color-red) !important;
}

.main-service[data-service-type='consulting']
  .umbraco-forms-submitmessage-image,
.main-service[data-service-type='defensive'] .umbraco-forms-submitmessage-image,
.main-service[data-service-type='offensive']
  .umbraco-forms-submitmessage-image {
  filter: brightness(0) invert(1);
}

.main-service[data-service-type='consulting'] .hero__title {
  color: var(--color-green) !important;
}

.main-service[data-service-type='defensive'] .hero__title {
  color: var(--color-blue) !important;
}

.main-service[data-service-type='offensive'] .hero__title {
  color: var(--color-red) !important;
}
