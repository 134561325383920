/* Zazalices ovo.. */
.main-careers
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  max-width: 500px;
}

.main-careers section:nth-child(3) .section-half-split__image-wrapper .swiper {
  max-height: 100%;
}

@media (width > 1356px) {
  .main-careers
    section:nth-child(3)
    .section-half-split__image-wrapper
    .swiper {
    max-height: calc(100% - 3rem) !important;
  }
}

.main-careers section:nth-child(3) .content-tabbed-content__arrows {
  display: none;
}

.main-careers section:nth-child(3) .swiper-pagination-bullet-active {
  background: white !important;
}

.main-careers section:nth-child(4) .section-half-split__content-wrapper {
  flex: 15;
}

.main-careers
  section:nth-child(4)
  .section-half-split__content-wrapper:first-child {
  flex: 5;
}

.logos .section-half-split__title {
  font-size: calc(var(--base-size) * 2.8);
  line-height: calc(var(--base-size) * 3.25);
  margin-top: 2.4rem;
}

.main-careers section:nth-child(4) .logo-banner {
  position: relative;
  left: -2rem;
  width: calc(100% + 4rem);
  margin: 0;
}

.main-careers section:nth-child(6) .section-half-split__image-wrapper {
  flex: 12;
}

@media (width < 1024px) {
  .main-careers section:nth-child(6) .section-half-split__image-wrapper {
    order: 2;
  }
}

.main-careers section:nth-child(6) .section-half-split__content-wrapper {
  flex: 14;
}

.main-careers section:nth-child(6) .section-half-split__image {
  object-fit: contain;
  scale: 1 !important;
  padding-top: 1rem;
  object-position: bottom;
}

.main-careers section:nth-child(6) .section-half-split__container {
  padding-right: 0;
}

.main-careers section:nth-child(6) .section-half-split__content {
  flex: 15;
}

.main-careers
  section:nth-child(6)
  .section-half-split__content-wrapper
  .section-half-split__text
  img {
  margin-top: -0.2rem;
  margin-right: 0.3rem;
}

@media (width < 1180px) {
  .main-careers section:nth-child(6) .section-half-split__container {
    flex-direction: column;
  }

  .main-careers section:nth-child(6) .section-half-split__image {
    max-width: 600px;
  }
}

.main-homepage section:nth-child(3) .section-half-split__title {
  font-size: calc(var(--base-size) * 2);
  line-height: calc(var(--base-size) * 2.2);
  max-width: 540px;
}

.main-homepage section:nth-child(3) .section-half-split__content .button {
  margin-bottom: 0.1rem;
}

.main-homepage section:nth-child(3) .section-half-split__content {
  justify-content: space-between;
}

.main-homepage
  section:nth-child(3)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 1;
}

.main-homepage
  section:nth-child(3)
  .section-half-split__content-wrapper:nth-child(3) {
  flex: 9;
}

.main-homepage section:nth-child(5) .section-half-split__image-wrapper {
  max-height: 22rem;
}

@media (width < 1024px) {
  .main-homepage section:nth-child(5) .section-half-split__image-wrapper {
    max-height: 32rem;
  }
}

.main-homepage section:nth-child(5) .section-half-split__image {
  object-position: top;
}

.main-homepage section:nth-child(5) .section-half-split__text {
  font-size: calc(var(--base-size) * 1.5);
  line-height: calc(var(--base-size) * 1.8);
  margin-top: 1rem;
}

.main-homepage section:nth-child(5) .section-half-split__content {
  height: 100%;
}

@media (width < 1400px) {
  .main-homepage section:nth-child(7) .cta__title {
    font-size: calc(var(--base-size) * 2);
    line-height: calc(var(--base-size) * 2.3);
  }
}

@media (width < 1400px) {
  .main-homepage section:nth-child(7) .cta__title {
    font-size: calc(var(--base-size) * 2);
    line-height: calc(var(--base-size) * 2.3125);
  }
}

@media (width < 1240px) {
  .main-homepage section:nth-child(7) .cta__title {
    max-width: 500px;
  }
}

@media (width < 887px) {
  .main-homepage section:nth-child(7) .cta__title {
    font-size: calc(var(--base-size) * 2.4);
    line-height: calc(var(--base-size) * 2.6);
  }
}

.main-homepage .section-half-split__content .button {
  margin-top: auto;
}

.main-homepage section:nth-child(7) .section-half-split__image {
  object-fit: contain;
  object-position: top;
  scale: 1 !important;
}

.main-homepage section:nth-child(7) .section-half-split__content-wrapper {
  flex: 30;
  min-width: 600px;
}

@media (width < 740px) {
  .main-homepage section:nth-child(7) .section-half-split__content-wrapper {
    min-width: unset;
  }
}

.main-homepage
  section:nth-child(7)
  .section-half-split__content-wrapper
  .section-half-split__text
  img {
  margin-top: -0.2rem;
  margin-right: 0.3rem;
}

.main-homepage section:nth-child(7) .section-half-split__image-wrapper {
  flex: 35;
  max-width: 690px;
}

.main-homepage section:nth-child(7) .section-half-split__container {
  padding-right: 0;
  flex-wrap: wrap;
}

@media (width < 1300px) {
  .main-homepage section:nth-child(7) .section-half-split__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .main-homepage section:nth-child(7) .section-half-split__image-wrapper {
    order: 1;
    max-width: 65%;
  }

  .main-homepage section:nth-child(7) .section-half-split__content .button {
    margin: 0 auto;
  }
}

@media (width < 600px) {
  .main-homepage section:nth-child(7) .section-half-split__image-wrapper {
    max-width: 100%;
  }
}

.main-homepage section:nth-child(7) .section-half-split__content .button {
  margin-bottom: 0.4rem;
}

.main-homepage section:nth-child(7) .section-half-split__text {
  font-size: calc(var(--base-size) * 1.2);
  line-height: calc(var(--base-size) * 1.6);
}

.main-homepage section:nth-child(7) .section-half-split__text br {
  margin: calc(var(--base-size)* .6) 0;
}

@media (width < 1520px) {
  .main-homepage section:nth-child(7) .section-half-split__text {
    font-size: calc(var(--base-size) * 1.1);
    line-height: calc(var(--base-size) * 1.3);
  }
}

@media (width < 1300px) {
  .main-homepage section:nth-child(7) .section-half-split__text {
    font-size: calc(var(--base-size) * 1.2);
    line-height: calc(var(--base-size) * 1.4);
  }
}

.main-homepage
  section:nth-child(7)
  .section-half-split__image-wrapper
  .main-homepage
  section:nth-child(3)
  .section-half-split__content-wrapper:nth-child(3) {
  flex: 10;
}

.main-homepage
  section:nth-child(7)
  .section-half-split__content-wrapper
  .main-homepage
  section:nth-child(3)
  .section-half-split__content-wrapper {
  flex: 6;
}

.main-service .cta__title {
  font-size: calc(var(--base-size) * 1.9);
  line-height: calc(var(--base-size) * 2.3);
}

.main-homepage .swiper-slide-gradient::after {
  display: none;
}

.main-service section:nth-child(3) .section-half-split__title::before {
  display: none;
}

.main-service section:nth-child(3) .section-half-split__title {
  text-align: center;
}

.main-privacy-policy section:last-child {
  margin-bottom: 4rem;
}

.main-resources .content-tabbed-content__tabs-wrapper {
  justify-content: flex-start;
}

.main-resources section:nth-child(2) .section-half-split__image-wrapper {
  height: 380px;
  max-height: 380px;
}

.main-resources section:nth-child(3) {
  margin-top: 0;
}

.main-range-product .section-half-split__image {
  object-fit: contain;
}

.main-range-product .section-half-split__content {
  gap: 1rem;
}

.main-range-product section:nth-child(3) .section-half-split__image-wrapper {
  flex: 23;
}

.main-range-product section:nth-child(3) .section-half-split__content-wrapper {
  flex: 22;
}

.main-range-product section:nth-child(4) .section-half-split__image-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(4) .section-half-split__content-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(5) .section-half-split__image-wrapper {
  flex: 18;
  height: 352px;
}

.main-range-product section:nth-child(5) .section-half-split__content-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(6) .section-half-split__image-wrapper {
  flex: 13;
}

.main-range-product section:nth-child(6) .section-half-split__content-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(7) .section-half-split__image-wrapper {
  flex: 19;
}

.main-range-product section:nth-child(7) .section-half-split__content-wrapper {
  flex: 20;
}


.main-range-product section:nth-child(8) .section-half-split__image-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(8) .section-half-split__content-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(9) .section-half-split__image-wrapper {
  flex: 20;
}

.main-range-product section:nth-child(9) .section-half-split__content-wrapper {
  flex: 20;
}

.main-range-product .section-half-split__title + .section-half-split__text {
  font-size: calc(var(--base-size) * 1.7);
  line-height: calc(var(--base-size) * 1.85);
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
}

.main-range .hero .logo-banner {
  margin-top: 2.3rem;
}

.main-homepage .hero {
  max-height: unset;
  min-height: unset;
  align-items: flex-start;
  padding: 0;
  min-height: calc(100vh - var(--header-height));
  padding-top: 3.6rem;
  max-width: 100vw;
}

@media (width > 940px) {
  .main-homepage .hero {
    padding-top: 5rem;
  }
}

@media (width > 1660px) {
  .main-homepage .hero {
    padding-top: 10rem;
  }
}

@media (height < 1020px) {
  .main-homepage .hero {
    padding-top: 4.6rem;
  }
}

@media (height > 800px) {
  .main-homepage .hero {
    /* align-items: center; */
  }
}

@media (height > 1080px) {
  .main-homepage .hero {
    min-height: unset;
    padding-bottom: 2rem;
    height: auto;
  }
}

@media (width < 1300px) {
  .main-homepage section:nth-child(7) .section-half-split__image-wrapper {
    flex: 4;
  }
}

main[data-service-name='red-team']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 10;
}

main[data-service-name='red-team']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='penetration-testing']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 11;
}

main[data-service-name='penetration-testing']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='threat-intelligence']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 10;
}

main[data-service-name='threat-intelligence']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='incident-response']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 10;
}

main[data-service-name='incident-response']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='risk-assessment']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 10;
}

main[data-service-name='risk-assessment']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='threat-hunting']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 12;
}

main[data-service-name='threat-hunting']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='security-consulting']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 8;
}

main[data-service-name='security-consulting']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='governance-risk-and-compliance']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 12;
}

main[data-service-name='governance-risk-and-compliance']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

main[data-service-name='security-assessment']
  section:nth-child(2)
  .section-half-split__content-wrapper:first-child {
  flex: 10;
}

main[data-service-name='security-assessment']
  section:nth-child(2)
  .section-half-split__content-wrapper:nth-child(2) {
  flex: 20;
}

#who-we-are .section-half-split__content-wrapper:first-child {
  flex: 12;
}

#who-we-are .section-half-split__content-wrapper:nth-child(2) {
  flex: 13;
}

#who-we-are .section-half-split__image-wrapper {
  flex: 17;
}