.swiper-slide {
  cursor: grab;
  border-radius: 0.6rem;
  background-position: center;
  background-size: cover;
  display: flex !important;
  justify-content: center;
}

.swiper-pagination {
  position: static;
}

.swiper-pagination-bullet-active {
  background: var(--hero-background) !important;
  scale: 1.3;
}

.mySwiperCarear {
  border-radius: calc(var(--border-radius));
  height: 90%;
  width: 600px;
}

.mySwiperTestimonial {
  max-width: 100%;
}

.mySwiperCarear img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.mySwiperCarear .swiper-slide {
  border-radius: calc(var(--border-radius));
  overflow: hidden;
}

.swiper-slide img {
  border-radius: 0.6rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.swiper {
  height: 100%;
  width: 100%;
}

.swiper-slide-gradient {
  position: relative;
}

.swiper-slide-gradient::before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  border-radius: 0.6rem;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.swiper-slide-thumb-active .content-tabbed-content__title::before {
  width: 100px;
}

.swiper-slide-thumb-active.swiper-slide-thumb-active.swiper-slide-gradient::before {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  ) !important;
}

.swiper-slide-thumb-active.swiper-slide-thumb-active
  .content-tabbed-content__button {
  z-index: 99;
}

.swiper-slide--split {
  display: flex;
}

.swiper-slide__image,
.swiper-slide__content {
  flex: 1;
}

.main-resources .swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}

.main-resources .swiper-pagination-bullet p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
}

.main-resources .swiper-pagination-bullet-active {
  color: var(--color-foreground);
  background: var(--hero-background);
  scale: unset;
}

.swiper-pagination.swiper-pagination-split {
  position: absolute !important;
  bottom: 2.5rem !important;
  right: 0 !important;
  left: unset !important;
  width: unset !important;
  height: 40px !important;
}

.content-tabbed-content__overview h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
  line-height: 2.2rem;
}

.content-tabbed-content__overview p {
  margin-bottom: 0.6rem;
}

.main-resources .swiper-slide {
  cursor: pointer;
}
