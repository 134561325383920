.features-cta__features {
  flex: 1;
}

.features-cta__content-wrapper {
  background: var(--gradient-cta);
  background-image: url(/assets/img/banner-flat.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: calc(var(--border-radius) * 0.6);
  color: var(--color-foreground);
  display: flex;
  gap: 1rem;
  overflow: hidden;
  padding: 3rem 5rem;
  position: relative;
}

.features-cta__features,
.features-cta__cta {
  z-index: 110;
}

.features-cta__cta {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.2rem;
}

.features-cta__list {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.features-cta__item {
  color: var(--color-foreground);
  display: flex;
  gap: 0.6rem;
}

.features-cta__checkmark {
  height: 2rem;
  width: 2rem;
}

.features-cta__text {
  font-size: calc(var(--base-size) * 1.3125);
  font-weight: 700;
}

.features-cta__headline {
  font-size: calc(var(--base-size) * 2.875);
  font-weight: 600;
  line-height: calc(var(--base-size) * 3.5);
}

@media (width < 1400px) {
  .features-cta__headline {
    font-size: calc(var(--base-size)* 2.55);
    line-height: calc(var(--base-size)* 3.1);
  }
}

.features-cta__headline br {
  margin: 0;
}

.features-cta__highlight {
  color: #0089d6;
  font-weight: 600;
}

.features-cta__subheadline {
  color: var(--color-foreground);
  font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: 600;
}

.features-cta__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: auto;
}

.features-cta__buttons button {
  font-size: calc(var(--base-size) * 1.125);
  min-width: 160px;
}

@media (width < 1024px) {
  .features-cta__content-wrapper {
    flex-direction: column;
    padding: 2rem;
  }

  .features-cta__cta {
    margin-bottom: 1rem;
    order: -1;
  }
}
