.logos__container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 3.4rem !important;
}

.logos__item img {
  height: 100px !important;
  max-width: 600px;
  object-fit: contain !important;
  max-width: 100%;
  border-radius: unset !important;
}

@media (width < 524px) {
  .logos__container {
    gap: 1rem;
  }

  .logos__item img {
    height: 120px !important;
    max-width: 200px;
    object-fit: contain !important;
  }
}

.logos__group:nth-child(odd) {
  padding: 0 2rem;
}

.logos__group:nth-child(even) {
  padding: 0 6rem;
}

@media (width < 800px) {
  .logos__group:nth-child(odd),
  .logos__group:nth-child(even)  {
    padding: 0 1rem;
  }
}

.logos__item img {
  height: 80px;
  width: auto;
  max-height: 80px;
}

.logos__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logos__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 500px;
  width: fit-content !important;
}

.logos__group {
  height: 100px;
}

.splide__slide {
  display: flex;
  cursor: grab;
}

.splide__slide img {
  margin: auto;
  max-height: 70px;
  min-height: 60px;
  width: 100%;
}