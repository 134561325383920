.recruitment-steps__content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
}

.recruitment-steps__step {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.4rem;
  text-align: center;
}

.recruitment-steps__icon-wrapper {
  align-items: center;
  background: var(--color-foreground);
  border-radius: 50%;
  box-shadow: 20px 13px 11px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 5.8125rem;
  justify-content: center;
  position: relative;
  width: 5.8125rem;
}

.recruitment-steps__icon-wrapper img {
  height: 118%;
  position: absolute;
  width: 124%;
}

.recruitment-steps__number {
  color: #5c5c5c;
  font-size: calc(var(--base-size) * 3.138);
  font-weight: 800;
  line-height: normal;
}

.recruitment-steps__text {
  font-size: calc(var(--base-size) * 1.25);
  font-weight: 500;
  line-height: calc(var(--base-size) * 1.5);
}
