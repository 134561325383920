@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-BlackItalic.woff2')
    format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-BoldItalic.woff2')
    format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-ExtraBold.woff2')
    format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-ExtraBoldItalic.woff2')
    format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-ExtraLight.woff2')
    format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-ExtraLightItalic.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-LightItalic.woff2')
    format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-MediumItalic.woff2')
    format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-SemiBold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-SemiBoldItalic.woff2')
    format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LiniK Sans';
  src: url('../assets/fonts/linik-sans/LinikSans-ThinItalic.woff2')
    format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

:root {
  --base-size: 1rem;
  --hero-padding: 6rem 0 4.7rem 0;
  --hero-background: #1b1e26;
  --hero-color: white;
  --border-radius: 1rem;
  --header-height: 4rem;
  --color-foreground: #fff;
  --color-darkgray: #010101;
  --color-light-gray: #f7f7fb;
  --color-medium-gray: #c6c6c5;
  --color-red: #e14646;
  --color-blue: #2487c9;
  --color-green: #00a59e;
  --gradient-cta: linear-gradient(90deg, #1c1e26 0, #414349 100%);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-margin: 5rem;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

html {
  font-size: calc(var(--base-size) * 1.125);
  line-height: 1.4;
  font-family: 'LiniK Sans', sans-serif;
  color: var(--color-darkgray);
  overflow: auto;
}

body {
  height: 100vh;
  overflow: auto;
  margin: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

br {
  content: '';
  display: block;
  margin: calc(var(--base-size) * 0.3) 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: calc(var(--base-size) * 1.6);
  text-wrap: balance;
}

h2 {
  font-size: calc(var(--base-size) * 1.6875);
}

a,
p {
  color: #5c5c5c;
  font-size: calc(var(--base-size) * 1.25);
  line-height: calc(var(--base-size) * 1.6);
  text-wrap: balance;
}

a,
li,
ul {
  color: #5b5b5b;
  list-style: none;
  text-decoration: none;
  text-wrap: balance;
}

img {
  font-size: calc(var(--base-size) * 0.5);
}

main {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 4rem;
  text-wrap: balance;
}

section {
  margin: 3.6rem 0;
  width: 100%;
  scroll-margin-top: 5rem;
}

section:first-child {
  margin-top: 0;
}

.hidden,
[hidden] {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}

.clearfix::after {
  clear: both;
}

button {
  cursor: pointer;
}

input,
input:active,
input:focus {
  outline: 0;
}

.grecaptcha-badge {
  display: none !important;
}

@media print {
  *,
  *::before,
  *::after {
    background: var(--color-foreground) !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: ' (' attr(href) ')';
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

@media (width < 1000px) {
  :root {
    --base-size: 0.9rem;
  }
}


@media (width < 800px) {
  :root {
    --base-size: 0.8rem;
  }
}

@media (width < 500px) {
  :root {
    --base-size: 0.7rem;
  }
}


/* @media (max-width: 768px) {
  :root {
    --base-size: 12px;
  }
}

@media (max-width: 480px) {
  :root {
    --base-size: 12px;
  }
} */
