

.newsletter__container {
  background: var(--gradient-cta);
  border-radius: calc(var(--border-radius) * 0.6);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  padding: 2.4rem 3rem;
}

.newsletter__container a {
  filter: brightness(0) invert(1);
  width: 55px;
  margin-top: 0.2rem;
  scale: 1;
  transition: scale 0.2s ease-in-out;
  margin: auto;
}

.newsletter:hover .newsletter__container a {
  scale: 1.07;
}

.newsletter__container p {
  font-size: calc(var(--base-size) * 1.6);
  line-height: calc(var(--base-size) * 2);
}

@media (width < 1500px) {
  .newsletter__container p {
    font-size: calc(var(--base-size) * 1.4);
    line-height: calc(var(--base-size) * 1.6);
  }
}

.newsletter__text-wrapper {
  align-items: center;
  color: var(--color-foreground);
  display: flex;
  flex: 6;
}

.newsletter__form-wrapper {
  align-self: center;
  flex: 4;
  width: 80%;
}

.newsletter__form-wrapper .umbraco-forms-page {
  background: white;
  border-radius: 8px;
  display: flex;
  gap: 0.3rem;
  padding: 0.6rem;
}

.newsletter__form-wrapper .umbraco-forms-page div {
  display: flex;
  height: 100%;
  position: relative;
}

.newsletter__form-wrapper .umbraco-forms-page .umbraco-forms-hidden {
  display: none;
}

.newsletter__form-wrapper .umbraco-forms-page input {
  border: none;
  color: var(--hero-background);
  display: flex;
  font-size: calc(var(--base-size) * 1.1);
  margin: 0;
  padding: 0.4rem;
}

.newsletter__form-wrapper .umbraco-forms-page input.input-validation-error {
  border: none !important;
}

.newsletter__form-wrapper .umbraco-forms-navigation {
  margin-left: auto;
}

.newsletter__form-wrapper .umbraco-forms-page .field-validation-error {
  bottom: -2.4rem;
  color: var(--color-red);
  font-size: calc(var(--base-size) * 0.8);
  font-weight: 500;
  left: -1.2rem;
  margin-left: 0.4rem;
  position: absolute;
  right: 0;
  text-align: center;
  width: 300px;
}

.newsletter__form-wrapper .umbraco-forms-page .btn.primary {
  background: var(--color-red);
  border-radius: 0.3rem;
  color: white;
  font-size: calc(var(--base-size) * 1);
  font-weight: 700;
  padding: 0.4rem 0.8rem;
}

.newsletter__form-wrapper .umbraco-forms-submitmessage {
  color: white;
  font-size: calc(var(--base-size) * 2);
  font-weight: 700;
}

.newsletter__form-group {
  display: flex;
  flex: 1;
  gap: 0.4rem;
}

.newsletter__text {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 3.8);
  font-weight: 800;
  line-height: calc(var(--base-size) * 2.7);
}

@media (width < 887px) {
  .newsletter__container p {
    font-size: calc(var(--base-size) * 2.2);
    line-height: calc(var(--base-size) * 2.4);
  }
}

@media (width < 1282px) {
  .newsletter__container p {
    font-size: calc(var(--base-size) * 1.6);
    line-height: calc(var(--base-size) * 2);
    max-width: 500px;
  }
}

@media (width < 887px) {
  .newsletter__container p {
    font-size: calc(var(--base-size) * 2);
    line-height: calc(var(--base-size) * 2.3);
    max-width: 100%;
    margin: 0 auto;
  }
}

.newsletter__form {
  background: var(--color-foreground);
  border-radius: calc(var(--border-radius) * 0.6);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.3rem;
}

.newsletter__label {
  align-self: center;
  font-weight: 600;
  margin-left: 1rem;
  white-space: nowrap;
}

.newsletter__input {
  align-self: center;
  border: none;
  flex: 1;
  height: 100%;
}

@media (width < 887px) {
  .newsletter__container {
    flex-direction: column;
    gap: 1rem 3rem;
  }

  .newsletter__text {
    text-align: center;
  }
}

@media (width < 480px) {
  .newsletter__form-wrapper .umbraco-forms-page {
    flex-wrap: wrap;
  }
}
