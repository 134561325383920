.promo__content-wrapper,
.promo__image-wrapper {
  flex: 1;
}

.promo__container {
  display: flex;
  padding: 1rem;
}

.promo__content {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.promo__headline {
  display: flex;
  flex-direction: column;
  font-size: calc(var(--base-size) * 2.8125);
  line-height: calc(var(--base-size) * 2.4);
}

.promo__highlight {
  color: #e04545;
}

.promo__image-wrapper {
  border-radius: calc(var(--border-radius) * 0.6);
  overflow: hidden;
}

.promo__image-wrapper img {
  display: flex;
  height: 100%;
  object-fit: contain;
  width: 100%;
}
