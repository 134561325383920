.team__container {
  padding: 1rem;
}

.team__members {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  justify-content: center;
}

.team__member-wrapper {
  flex: 1;
  max-width: 25%;
  min-width: 260px;
  position: relative;
}

.team__member,
.team__member-info {
  display: flex;
  flex-direction: column;
}

.team__member-wrapper::before {
  background: #f2efef;
  border-bottom: 0.15rem solid var(--hero-background);
  border-radius: calc(var(--border-radius) * 0.6);
  border-top: 0.15rem solid var(--hero-background);
  content: '';
  inset: 0;
  position: absolute;
  top: 3.5rem;
  z-index: 100;
}

.team__member {
  position: relative;
  text-align: center;
  z-index: 110;
}

.team__member-photo-wrapper {
  border: 0.15rem solid var(--hero-background);
  border-radius: 50%;
  height: 7rem;
  margin: 0 auto;
  overflow: hidden;
  width: 7rem;
}

.team__member-photo {
  height: 100%;
  width: 100%;
}

.team__member-info {
  gap: 0.6rem;
  line-height: calc(var(--base-size) * 1);
  padding: 2rem 1rem;
}

.team__member-name {
  font-size: calc(var(--base-size) * 1.73219);
  font-weight: 900;
}

.team__member-role {
  font-weight: 700;
}

.team__member-description {
  font-size: calc(var(--base-size) * 1.11481);
  font-weight: 400;
  line-height: calc(var(--base-size) * 1.2);
}

@media (width < 480px) {
  .team__member-wrapper {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}
