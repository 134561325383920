@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-in forwards;
}

.fade-out {
  animation: fadeOut 0.2s ease-out forwards;
}
