.range-tabs-section {
  background: var(--gradient-cta);
  background-image: url(/assets/img/banner-flat.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
  display: flex;
  padding: 2rem 1rem;
  position: relative;
  max-height: 43rem;
  overflow: hidden;
}

.range-tabs .services__checkmark {
  display: flex;
  height: 1.6rem !important;
  margin-top: 0;
  object-fit: contain;
  width: 1.6rem !important;
}

.range-tabs {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  padding-top: 2.6rem;
  width: 100%;
  overflow: hidden;
  margin-top: 3rem;
}

.range-tabs__nav,
.range-tabs__nav.swiper-pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
  justify-content: space-around;
  margin-bottom: 20px;
  width: 100%;
  top: 2.8rem !important;
  left: 0 !important;
  margin: 0 auto;
}

.range-tabs .range-tabs__nav {
  position: static !important;
}

.range-tabs .swiper-slide {
  display: flex;
}

.range-tabs__tab {
  background: white;
  padding: 1rem;
}

.range-tabs__nav .swiper-pagination-bullet-active {
  background: none !important;
}

.range-tabs__tab--active {
  background: var(--color-red) !important;
}

.range-tabs__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 1.6rem;
}

.range-tabs-wrapper {
  position: relative;
}

.range-tabs-wrapper::before {
  background: var(--color-red);
  content: '';
  height: 0.25rem;
  left: 0;
  position: absolute;
  top: -1rem;
  width: 3.6rem;
}

.range-tabs__panel {
  display: none;
  flex-wrap: wrap;
  height: 100%;
}

.range-tabs__panel--active {
  display: flex;
}

.range-tabs__left,
.range-tabs__right {
  max-height: 100%;
}

.range-tabs__left {
  display: flex;
  flex: 5;
  flex-direction: column;
  gap: 1.8rem;
  padding: 20px;
  justify-content: center;
}

.range-tabs__buttons {
  display: flex;
  gap: 0.6rem;
  margin-top: 1rem;
}

.range-tabs__left .button {
  align-self: baseline;
  padding: 0.4rem 1.7rem;
}

.range-tabs__right {
  flex: 7;
  padding: 0px;
}

.range-tabs__right img {
  width: 100% !important;
  object-fit: contain !important;
}

.range-tabs__features {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.range-tabs__features li {
  color: #e5e5e5;
  display: flex;
  font-size: calc(var(--base-size) * 1.15);
  font-weight: 500;
  gap: 0.6rem;
}

.range-tabs__headline {
  color: #e5e5e5;
  font-size: calc(var(--base-size) * 2.5);
  line-height: calc(var(--base-size) * 2.75);
  position: relative;
}

.range-tabs__button--learn {
  color: #000;
}

@media (width < 1024px) {
  .range-tabs {
    padding-top: 6rem;
  }

  .range-tabs-section {
    max-height: 70rem;
  }

  .range-tabs__panel {
    flex-direction: column;
    gap: 2rem;
  }

  .range-tabs__right,
  .range-tabs__left {
    flex: 0;
  }

  .range-tabs__right {
    order: -1;
    max-width: 80%;
    margin: 0 auto;
  }

  .range-tabs__nav.swiper-pagination {
    top: 5.8rem !important;
  }
}
