.cta-banner__container {
  align-items: center;
  background: var(--gradient-cta);
  background-image: url(/assets/img/banner-flat.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: calc(var(--border-radius) * 0.6);
  color: var(--color-foreground);
  display: flex;
  padding: 4rem 3rem;
  position: relative;
}

.cta-banner__container .button--primary {
  font-size: calc(var(--base-size) * 1.375);
  font-weight: 900;
  margin: 0 2rem;
  padding: 1rem 2rem;
}

.cta-banner__text {
  color: var(--color-foreground);
  flex: 1;
  font-size: calc(var(--base-size) * 2.8125);
  font-weight: 800;
}
