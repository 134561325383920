.content-tabbed-content__tabs-wrapper {
  display: flex;
  justify-content: center;
}

.content-tabbed-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 3rem;
  position: relative;
}

.content-tabbed-content__pane {
  position: relative;
  flex-direction: column;
}

.content-tabbed-content__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  padding: 1rem;
}

.content-tabbed-content__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
}

.content-tabbed-content__items {
  display: flex;
  position: relative;
}

.content-tabbed-content__tab {
  background: 0 0;
  border: none;
  border-radius: calc(var(--border-radius) * 0.3);
  font-size: calc(var(--base-size) * 1.375);
  font-weight: 800;
  padding: 0.6rem 1.4rem;
}

.content-tabbed-content__tab--active {
  background: #3b3d44;
  color: var(--color-foreground);
}

.content-tabbed-content__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  position: relative;
  width: 100%;
}

.content-tabbed-content__image {
  height: 100%;
  object-fit: contain !important;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.content-tabbed-content__title {
  align-items: end;
  color: var(--color-foreground);
  cursor: pointer;
  display: flex;
  font-size: calc(var(--base-size) * 1.5);
  font-weight: 700;
  height: 3rem;
  inset: 0;
  left: 2rem;
  line-height: 28px;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 2rem;
  vertical-align: bottom;
  width: 75%;
  z-index: 110;
}

.swiper-slide--split {
  gap: 1rem;
  display: flex;
}

.swiper-slide__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.swiper-slide__content .content-tabbed-content__title {
  position: relative;
  color: unset;
  inset: unset;
  padding-top: 1.2rem;
  height: unset;
}

.content-tabbed-content__subtitle {
}

.content-tabbed-content__description {
}

.content-tabbed-content__title::before {
  background: var(--color-red);
  bottom: -1rem;
  content: '';
  height: 0.2rem;
  position: absolute;
  transition: width 0.2s ease-in-out;
  width: 80px;
}

.swiper-slide__content .content-tabbed-content__title::before {
  top: 0;
}

.swiper-slide__image {
  border-radius: 0.4rem;
  background-position: center;
  background-size: cover;
}

.content-tabbed-content__title:hover::before {
  width: 100px;
}

.content-tabbed-content__button {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
}

.swiper-slide__content .content-tabbed-content__button {
  position: static;
  align-self: baseline;
  margin-top: auto;
}

.content-tabbed-content__arrows {
  align-items: center;
  display: flex;
  height: calc(100% - 4.5rem);
  inset: 0;
  justify-content: space-between;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 150;
  padding: 0 calc(var(--base-size) * 0.31);
  height: 100%;
}

@media (width < 600px) {
  .content-tabbed-content__arrows {
    /* display: none; */
  }

  .content-tabbed-content {
    padding: 0 calc(var(--base-size) * 1);
  }
}

.content-tabbed-content__arrow {
  background: 0 0;
  border: none;
  height: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  pointer-events: all;
  width: 2rem;
}

.content-tabbed-content__arrow img {
  height: 100%;
  width: 100%;
}

.content-slider {
  position: relative;
  padding: 0 2.6rem !important;
}

.content-slider .swiper-wrapper {
  height: calc(100% - 2.5rem);
}