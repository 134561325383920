.cyber-training__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
}

.cyber-training__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cyber-training__contentr {
  display: flex;
  gap: 1rem;
}

.cyber-training__text-wrapper {
  flex: 5;
}

.cyber-training__headline {
  font-size: calc(var(--base-size) * 2.8125);
  font-weight: 700;
  text-align: center;
}

.cyber-training__subheadline {
  font-size: calc(var(--base-size) * 1.625);
  font-weight: 500px;
  text-align: center;
}

.cyber-training__secondary-headline {
  font-size: calc(var(--base-size) * 2.5);
  font-weight: 700;
  line-height: calc(var(--base-size) * 2.4);
}

.cyber-training__description {
  margin: 1.6rem 0;
}

.cyber-training__image-wrapper {
  flex: 6;
}

.cyber-training__image {
  height: 100%;
}

.cyber-training__image img {
  display: flex;
  height: 100%;
  object-fit: contain;
  width: 100%;
}
