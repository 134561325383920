.footer__legal {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.footer {
  background: var(--hero-background);
  color: var(--color-foreground);
  display: flex;
  font-size: calc(var(--base-size) * 1.0625);
  justify-content: center;
  padding: 2rem 1rem;
}

.footer__container {
  display: flex;
  flex-direction: column;
  gap: 7rem;
  justify-content: space-between;
  width: 100%;
}

.footer__bottom,
.footer__top {
  display: flex;
  justify-content: space-between;
}

.footer__bottom {
  flex-wrap: wrap;
  gap: 3rem 0;
}

.footer__top {
  flex-wrap: wrap;
  gap: 3rem 0;
}

.footer__branding {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 0.5rem;
}

.footer__nav {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: space-between;
}

.footer__logo,
.recruitment-steps__step-wrapper {
  width: 220px;
}

.footer__logo {
  width: 260px;
}

.footer__partner-logo {
  width: 160px;
}

.footer__social-media {
  display: flex;
  gap: 0.6rem;
}

.footer__social-icon {
  display: flex;
  height: 1.8rem;
  width: 1.8rem;
}

.footer__social-icon-img,
.mission__image {
  height: 100%;
  width: 100%;
}

.footer__nav-column {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
}

.footer__nav-title {
  font-size: calc(var(--base-size) * 1.1875);
  font-weight: 700;
}

.footer__nav-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer__nav-link,
.footer__nav-item .header__dropdown-link {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 0.9);
  font-weight: 400;
  height: unset;
}

.footer__legal-link,
.footer__copyright {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 0.9);
  font-weight: 400;
}

.footer__nav-item .header__dropdown-link:hover {
  color: white;
}

@media (width < 1024px) {
  .footer__nav {
    justify-content: flex-start;
  }
}

@media (width < 480px) {
  .footer__nav {
    flex-direction: column;
    min-width: 100%;
  }
}
