.main-inner-page .contact {
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 2rem;
}

.main-inner-page .contact__right {
  max-width: unset !important;
  padding: 1rem 3rem;
}

.main-inner-page h3 {
  position: relative;
  margin: 1rem 0;
  margin-bottom: 1.6rem;
  font-size: calc(var(--base-size) * 1.6);
}

.main-inner-page h3::before {
  background: var(--color-red);
  content: '';
  height: 0.25rem;
  left: 0;
  position: absolute;
  transition: all 0.2s ease;
  width: 3.6rem;
  bottom: -0.5rem;
}

.main-inner-page h4 {
  margin: 0.7rem 0;
  font-size: calc(var(--base-size) * 1.3);
}

.main-inner-page p {
  margin: 0.8rem 0;
}

.main-inner-page ul {
  padding-left: 2rem;
}

.main-inner-page ul li {
  list-style: disc;
  margin: 0.6rem 0;
}

.main-inner-page ul li::marker {
  color: var(--color-red);
}

.main-inner-page thead td {
  background: #eaeaea;
}

.main-inner-page table td {
  padding: 1rem;
}

.main-inner-page table td p {
  margin: 0 !important;
}

.main-inner-page table p {
  margin: 0 !important;
}

.main-inner-page table span {
  line-height: 0.8rem;
}

.table-of-contents > ul {
  margin-bottom: 5rem;
  border-left: 0.3rem solid rgb(246, 246, 246);
  padding: 0.2rem 2rem;
}

.table-of-contents > ul > li {
  list-style: none;
  margin: 1rem 0 !important;
}

.table-of-contents > ul > li > a {
  color: black;
  font-weight: 600;
}

.table-of-contents ul li {
  margin: 0.1rem 0;
}

.table-of-contents li > ul {
  margin: 0.8rem 0;
}

.table-of-contents a {
  text-decoration: none !important;
}

.article-page-contents img {
  width: 100%;
  height: auto;
}

.article-page-contents a {
  text-decoration: underline;
  text-decoration-color: var(--color-red);
  word-wrap: anywhere;
}

.article-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.article-title {
  font-size: calc(var(--base-size) * 2.5);
  line-height: calc(var(--base-size) * 2.75);
  max-width: 500px;
}

.article-image {
  border-radius: 1rem;
  height: 500px;
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;
}

.article-hero-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.article-image::before {
  background: radial-gradient(circle, transparent, rgba(0, 0, 0, 0.8));
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-brand-logo {
  left: 1rem;
  position: absolute;
  top: 1rem;
  width: 260px;
}

.article-image-title {
  bottom: 1rem;
  color: white;
  font-size: calc(var(--base-size) * 3.75);
  line-height: calc(var(--base-size) * 4);
  left: 1rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: calc(100% - 2rem);
  font-weight: 800;
}

.blog-table-wrapper {
  max-width: 100%;
  overflow: auto;
  margin-top: 2rem;
}

.blog-table-wrapper p {
  word-wrap: anywhere;
}

.blog-code-block {
  background-color: #2d2d2d;
  color: #f8f8f2;
  font-family: Consolas, Monaco, monospace;
  padding: 20px;
  border-radius: 5px;
  overflow-x: auto;
  line-height: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 2rem;
}

.blog-code-block .MsoNormal {
  margin: 0 !important;
  line-height: 1.4rem;
}

.blog-code-block .indent {
  padding-left: 20px;
}

.blog-code-block p {
  color: #e74c3c;
}

.blog-code-block .comment {
  color: #b0b0b0;
  font-style: italic;
}

.blog-code-block .MsoNormal .comment {
  color: #b0b0b0;
}

.blog-code-block .keyword {
  color: #f92672;
  font-weight: bold;
}
