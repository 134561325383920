@import './base.css';
@import './vendor/intlTelInput.css';
@import './vendor/swiper-bundle.css';
@import './vendor/splide.min.css';
@import './shared/shared.css';
@import './shared/buttons.css';
@import './custom/iti.css';
@import './custom/swiper.css';
@import './modular/header.css';
@import './modular/disclamer.css';
@import './modular/hero.css';
@import './modular/section-half-split.css';
@import './modular/services.css';
@import './modular/related-brands.css';
@import './modular/cta-banner.css';
@import './modular/promo.css';
@import './modular/features-cta.css';
@import './modular/newsletter.css';
@import './modular/tabbed-content.css';
@import './modular/job-openings.css';
@import './modular/job-offer.css';
@import './modular/articles.css';
@import './modular/range-tabs.css';
@import './modular/team-members.css';
@import './modular/cyber-training.css';
@import './modular/testimonials.css';
@import './modular/steps.css';
@import './modular/recruitment.css';
@import './modular/range-use-cases.css';
@import './modular/faq.css';
@import './modular/footer.css';
@import './modular/cookie.css';
@import './modular/fade-animations.css';
@import './modular/legal.css';
@import './modular/logos.css';
@import './shared/contact-us-form.css';
@import './shared/job-offer-form.css';
@import './shared/whatsapp.css';
@import './shared/social-share.css';
@import './custom/style-modifications.css';

.gallery__items-wrapper {
  position: relative;
}

.mission__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.mission__image-wrapper {
  flex: 1;
}

.gallery__items,
.newsletter__button-wrapper,
.recruiting__container,
.safer-world__container {
  display: flex;
  gap: 1rem;
}

.safer-world__description-wrapper {
  flex: 6;
}

.trusted__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
}

.mission__content-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.33rem;
}

.mission__image {
  display: flex;
  object-fit: contain;
}

.life-at-hackers__image-wrapper {
  flex: 1;
  position: relative;
}

.cta__container {
  align-items: center;
  background: var(--gradient-cta);
  background-image: url('/assets/img/banner-1.png');
  background-position: center;
  background-size: cover;
  border-radius: calc(var(--border-radius) * 0.6);
  color: var(--color-foreground);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 3rem 2.6rem;
}

.cta__title {
  font-size: calc(var(--base-size) * 2.4);
  font-weight: 800;
  line-height: calc(var(--base-size) * 3);
}

@media (width < 640px) {
  .cta__title {
    font-size: calc(var(--base-size) * 1.6);
    font-weight: 800;
    line-height: calc(var(--base-size) * 2);
  }
}

.gallery__container {
  height: 300px;
}

.gallery__image {
  width: 100%;
}

.gallery__arrows {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: space-between;
  position: absolute;
  z-index: 150;
}

.gallery__arrow {
  background: 0 0;
  border: none;
  height: 2rem;
  width: 2rem;
}

.gallery__arrow img {
  height: 100%;
  width: 100%;
}

.trusted__content-wrapper {
  flex: 8;
}

.trusted__description-wrapper {
  flex: 12;
}

.safer-world__content-wrapper {
  flex: 4;
}

.life-at-hackers__title,
.recruiting__title,
.safer-world__headline {
  font-size: calc(var(--base-size) * 2.8125);
  line-height: calc(var(--base-size) * 3.125);
}

.life-at-hackers__container {
  display: flex;
  gap: 2rem;
}

.life-at-hackers__content-wrapper,
.recruiting__content-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}

.values__container {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
}

.values__item {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.values__icon {
  display: flex;
  height: 145px;
  width: 145px;
}

.recruiting__image-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}

.privacy-policy__toc {
  display: flex;
}

.privacy-policy__toc-container {
  background: --color-light-gray;
  border: 0.15rem solid --color-medium-gray;
  border-radius: calc(var(--border-radius) * 0.6);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.4rem 2.3rem;
}

.privacy-policy__toc-title {
  color: var(--color-darkgray);
  font-size: calc(var(--base-size) * 1.5625);
  font-weight: 500;
}

.privacy-policy__toc-list {
  display: flex;
  flex-direction: column;
  padding-left: 1.6rem;
}

.privacy-policy__toc-item {
  list-style: disc;
}

.privacy-policy__toc-item a {
  color: var(--color-darkgray);
  font-size: calc(var(--base-size) * 1.125);
  font-weight: 700;
}

.cookies__update p,
.privacy-policy__update-date {
  font-weight: 700 !important;
  margin-bottom: 1rem;
}

.popup {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.popup-content {
  align-items: center;
  background: #1d1f26;
  border: 0.0625rem solid #888;
  border-radius: 0.6rem;
  display: flex;
  max-height: 70vh;
  overflow: visible;
  padding: 1.25rem 3.0625rem;
  padding-bottom: 3rem;
  padding-top: 4rem;
  position: relative;
  width: 46.875rem;
  margin: 0 2rem;
}

.popup-content .umbraco-forms-submitmessage-wrapper {
  padding: 2rem;
}

@media (height < 940px) {
  .popup-content {
    height: calc(100% - 2rem);
    max-height: calc(100% - 6rem);
    padding: 1rem 2.2rem;
    padding-top: 1.4rem;
    padding-top: 3rem;
  }
}

@media (width < 640px) {
  .popup-content {
    border: none;
    border-radius: 0;
    height: 100vh;
    inset: 0;
    margin: 0;
    max-height: 100vh;
    padding: 1rem;
    justify-content: flex-start;
    width: 100vw;
    align-items: flex-start;
    padding-top: 6%;
  }
}

.popup-content-logo {
  height: 5.375rem;
  left: calc(50% - 4.5rem);
  position: absolute;
  top: -2.6875rem;
  width: 9.6875rem;
}

@media (width < 640px) {
  .popup-content-logo {
    height: 40px;
    top: 0.7rem;
    left: -2rem;
    display: none;
  }
}

@media (height < 710px) {
  .popup-content-logo {
    width: 2.6rem;
    left: 0.8rem;
    top: 2.7rem;
    display: none;
  }
}

/*

 */

.umbraco-forms-submitmessage-wrapper.jobs-form {
  background: #fbfcfd;
  border: 0.15rem solid #d2d8d6;
  border-radius: 0.4rem;
  padding: 1rem;
}

.umbraco-forms-submitmessage-wrapper.jobs-form .umbraco-forms-submitmessage {
  color: var(--hero-background);
}

.umbraco-forms-submitmessage-wrapper.jobs-form
  .umbraco-forms-submitmessage-image-wrapper {
  background: var(--hero-background);
  height: 400px;
  width: 400px;
}

.umbraco-forms-submitmessage-wrapper.jobs-form
  .umbraco-forms-submitmessage-image {
  filter: brightness(0) invert(1);
  height: 240px;
  width: 240px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: calc(var(--base-size) * 1.75);
  font-weight: bold;
  position: absolute;
  right: 1rem;
  top: 0.2rem;
}

.close-btn:hover,
.close-btn:focus {
  cursor: pointer;
}

@media (width < 887px) {
  .cta__container {
    flex-direction: column;
    gap: 1rem 3rem;
    text-align: center;
  }
}

.main-inner-page img {
  max-width: 100%;
}

.main-inner-page p,
.main-inner-page h1,
.main-inner-page h2,
.main-inner-page h3,
.main-inner-page h4,
.main-inner-page h5,
.main-inner-page h6,
.main-inner-page a,
.main-inner-page span {
  font-family: 'LiniK Sans', sans-serif !important;
}

.main-inner-page a {
  word-wrap: anywhere;
}

.inner-page-container {
  display: flex;
  flex-direction: column;
  padding-top: 1.4rem;
}

.umbraco-forms-CustomTheme .umbraco-forms-field.checkbox a {
  text-decoration: underline;
}

.checkbox {
  cursor: pointer;
}

input[type='checkbox'] {
  height: 20px !important;
  width: 20px !important;
}

.validation-summary-errors {
  display: none;
}

.input-validation-error {
  border: 0.15rem solid var(--color-red) !important;
}

.input-validation-error + label * {
  color: var(--color-red) !important;
}

.content-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 6px 0;
  margin-top: 12px;
}

.content-tag {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: calc(var(--base-size) * 0.875);
}

.content-grid-section .content-tag {
  cursor: pointer;
}

.content-tag.content-tag--active {
  background-color: #242424;
  color: var(--color-foreground);
}

@media screen and (min-width: 1000px) {
  .content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 25px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 999px) and (min-width: 501px) {
  .content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 25px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 560px) {
  .content-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px 25px;
    margin-top: 20px;
  }
}

.content-grid-item {
  background: var(--color-foreground);
  border-radius: 8px;
  overflow: hidden;
}

.grid-image {
  background-position: 50%;
  background-size: cover;
  height: 240px;
  display: flex;
  border-radius: 0.4rem;
  position: relative;
  margin-bottom: 1rem;
}

.grid-image:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45), transparent);
  border-radius: 0.6rem;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.grid-content h3 {
  font-size: calc(var(--base-size) * 1.5);
  margin-bottom: 16px;
  color: #20222a;
  font-weight: 900;
  line-height: calc(var(--base-size) * 1.8);
}

.grid-content p {
  font-size: calc(var(--base-size) * 0.875);
  color: #555;
}

.content-date {
  font-weight: 600;
  margin-top: 0.6rem;
}

.big-preview-section {
  margin: 1rem 0;
  margin-top: 1.6rem;
}

.big-preview .swiper-slide {
  gap: 2rem;
  cursor: unset;
}

.big-preview .swiper-slide > a:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), transparent);
  border-radius: 0.6rem;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  width: calc(50% - 1rem);
}

.big-preview .swiper {
  height: 400px !important;
  align-items: center;
  display: flex;
}

.big-preview .swiper-wrapper {
  height: 100%;
}

.big-preview .swiper-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.big-preview .swiper-slide .content-tabbed-content__title {
  line-height: calc(var(--base-size) * 2.5);
}

.big-preview .swiper-slide__content h2 {
  font-size: calc(var(--base-size) * 2.4);
  line-height: calc(var(--base-size) * 2.6);
}

.big-preview .share-buttons {
  position: static;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: 0;
  margin-top: auto;
  gap: 1rem;
}

.big-preview .share-button {
  border: none;
}

.big-preview .share-button img {
  height: 35px;
  width: auto;
  border-radius: 0;
}

.big-preview .share-button:nth-child(1) {
  order: 1;
}

.big-preview .share-button:nth-child(2) {
  order: 2;
}

.big-preview .share-button:nth-child(3) {
  order: 4;
}

.big-preview .share-button:nth-child(4) {
  order: 3;
  width: 20px;
}

@media (width < 800px) {
  .big-preview .swiper {
    height: 700px !important;
  }
  .swiper-slide__image {
    min-height: 300px;
  }
  .big-preview .swiper-slide {
    flex-direction: column;
  }

  .big-preview .swiper-slide > a:before {
    display: none;
  }
}
