.hero {
  align-items: center;
  background: var(--hero-background);
  color: var(--hero-color);
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 45vh;
  padding: 4.6rem 0;
  max-height: 68vh;
}

.hero__container {
  max-width: 100%;
}

.hero__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.hero__content h1 {
  font-size: calc(var(--base-size) * 3);
  line-height: calc(var(--base-size) * 3);
  padding: 0 0.8rem;
  text-align: center;
}

.hero__title p {
  color: white;
  font-weight: bold;
  font-size: calc(var(--base-size) * 3);
  line-height: calc(var(--base-size) * 3);
  padding: 0 0.8rem;
  text-align: center;
}

.hero__content h1 strong {
  display: inline-block;
  background: linear-gradient(45deg, var(--color-red), #8b2b8b, #0089d6);
  background-size: 1000%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 12s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.hero__logo {
  max-width: 80%;
}

.hero__logo > img {
  max-width: 100%;
  width: 600px;
  height: 100px;
}

.hero__title {
  font-size: calc(var(--base-size) * 3);
  line-height: calc(var(--base-size) * 3);
  padding: 0 1rem;
  text-align: center;
}

.hero__subtitle {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 1.5);
  font-weight: 600;
  max-width: 900px;
  text-align: center;
}

@media (width < 1560px) {
  .hero {
    background: var(--hero-background);
    color: var(--hero-color);
    justify-content: center;
    margin: 0;
    max-height: 68vh;
    min-height: 45vh;
    padding: 3.2rem 0;
  }
}

@media (width < 560px) {
  .hero__content h1 {
    font-size: calc(var(--base-size) * 2.6);
    line-height: calc(var(--base-size) * 2.8);
  }
}

@media (width < 1300px) {
  .hero__content h1 {
    font-size: calc(var(--base-size) * 2.6);
    line-height: calc(var(--base-size) * 2.8);
  }

  .hero__logo {
    max-width: 440px;
    height: 83px;
  }

  .main-homepage .hero.hero-with-brands {
    padding-top: calc(var(--base-size) * 3.4);
    min-height: unset;
  }
}

@media (width < 1300px) {
  .cta__title {
    font-size: calc(var(--base-size) * 2.2);
    line-height: calc(var(--base-size) * 2.4);
  }
}

@media (width < 1000px) and (height > 800px) {
  .main-homepage .hero {
    max-height: unset !important;
    min-height: unset !important;
  }
}

.hero.hero-with-brands + .logo-banner {
  display: none;
}

.hero .logo-banner {
  margin: 0;
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 1.4rem;
  position: relative;
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 25%,
    #000 75%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 25%,
    #000 75%,
    transparent 100%
  );
  padding: 0 !important;
  overflow: hidden;
}

@media (height < 840px) {
  .hero .logo-banner {
    margin-top: 1rem;
  }

  .hero .logo-banner .swiper-slide {
    height: 60px;
  }
}

.hero .logo-banner .swiper-slide {
  width: fit-content !important;
  max-width: 320px;
}

.hero .logo-banner .splide__slide img {
  filter: brightness(0) invert(1);
}

@media (width < 400px) and (height > 1080px) {
  .hero {
    max-height: unset;
    min-height: unset;
    height: auto;
  }
}
