.header,
.header__cta,
.header__top {
  display: flex;
}

.header__cta .button--cta {
  margin: auto;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--hero-background);
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 1);
  font-weight: 600;
  justify-content: center;
  width: 100%;
  z-index: 999;
  padding: 0 1rem;
  height: var(--header-height);
}

.header__nav-item,
.header__nav-list {
  position: relative;
}

.header__dropdown-item:hover .header__submenu,
.header__dropdown:hover,
.header__nav-item:hover .header__dropdown,
.header__submenu:hover {
  display: block;
}

.header__dropdown-item:hover > a {
  position: relative;
}

.header__dropdown-link:hover,
.header__submenu-link:hover {
  color: var(--color-red);
}

.header__top {
  align-items: center;
  padding: 0.6rem 0;
  width: 100%;
}

.header__logo--small {
  display: none;
}

.header__sidebar-mobile-toggle {
  display: none;
}

.header__support {
  margin-left: auto;
}

.header__support-image {
  position: relative;
}

.header__support-image img {
  filter: brightness(0) invert(1);
}

.header__support-image:hover .header__support-tooltip {
  display: flex;
}

.header__support-tooltip {
  background: white;
  border-radius: 0.6rem;
  color: var(--hero-background);
  display: none;
  flex-direction: column;
  font-size: calc(var(--base-size) * 1);
  gap: 0.6rem;
  height: unset;
  left: -0.6rem;
  margin-top: 0.8rem;
  padding: 0.6rem;
  position: absolute;
  right: unset;
  top: 100%;
  width: 260px;
}

.header__support-tooltip-title,
.header__support-tooltip-text {
  font-size: calc(var(--base-size) * 0.8);
  font-weight: 600;
  line-height: calc(var(--base-size) * 1);
}

.header__support-tooltip::before {
  border-bottom: 8px solid var(--color-foreground);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: '';
  height: 0;
  left: 26px;
  position: absolute;
  top: -8px;
  width: 0;
}

.header__cta {
  margin-left: 1rem;
}

.header__menu-btn {
  background: none;
  border: none;
  display: none;
  margin-left: 1rem;
  width: 50px;
}

.header__menu-btn > img {
  filter: brightness(0) invert(1);
  height: 100%;
  width: 100%;
}

.header__nav-list {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.header__dropdown,
.header__submenu {
  display: none;
  position: absolute;
  z-index: 10;
}

.header__nav-link {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 1);
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
}

.header__dropdown-link,
.header__submenu-link {
  align-items: center;
  color: #000;
  display: flex;
  font-weight: 600;
  height: 50px;
  padding-right: 1rem;
  text-decoration: none;
  white-space: nowrap;
}

.header__submenu-link {
  font-size: calc(var(--base-size) * 1);
}

.header__dropdown-text {
  margin-left: 1rem;
}

.header__submenu-link {
  font-weight: 600;
}

button.header__dropdown-link {
  background: none;
  border: none;
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 1.25);
  font-weight: 600;
  line-height: calc(var(--base-size) * 1.4);
}

.header__dropdown {
  left: 0;
  top: 100%;
}

.header__dropdown-list {
  background: var(--color-foreground);
  border-radius: 0.4rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  margin-top: 0.4rem;
  padding: 0.6rem;
  padding-right: 0rem;
  position: relative;
}

.header__dropdown-list::before {
  border-bottom: 8px solid var(--color-foreground);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: '';
  height: 0;
  left: 20px;
  position: absolute;
  top: -8px;
  width: 0;
}

.header__dropdown-item,
.header__submenu-item {
  padding: 0.3rem 0.7rem;
}

.header__submenu-item {
  background: #eff5f7;
  padding-left: 2.2rem;
}

.header__submenu-item:first-child {
  border-top-right-radius: 8px;
}

.header__submenu-item:last-child {
  border-bottom-right-radius: 8px;
}

.header__dropdown-item button {
  background: none;
  border: none;
  color: #000;
  font-size: calc(var(--base-size) * 1.25);
  line-height: calc(var(--base-size) * 1.4);
  text-decoration: none;
  white-space: nowrap;
}

.header__dropdown-image {
  width: 40px;
}

.header__submenu {
  background: white;
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  left: 100%;
  padding: 0.6rem;
  padding-left: 0;
  top: 0;
}

.header__submenu::before {
  background: white;
  content: '';
  height: 100%;
  left: -10px;
  position: absolute;
  top: 0;
  width: 10px;
}

.header__submenu::after {
  background: var(--hero-background);
  content: '';
  height: calc(100% - 1.2rem);
  left: 0;
  position: absolute;
  top: 0.6rem;
  width: 0.15rem;
}

@media (width < 1024px) {
  .header {
    padding: 0.6rem;
  }

  .header__logo {
    display: none;
  }

  .header__logo--small {
    display: flex;
  }
}

@media (width < 887px) {
  .header__nav {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: width 0.3s ease-in-out;
    width: 0;
    z-index: 1000;
  }

  .header__nav.open {
    width: 420px;
    max-width: 100%;
  }

  .header__dropdown-list {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .header__nav-list {
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 0.8rem;
    overflow: auto;
  }

  .header__nav-link {
    color: var(--hero-background);
    font-size: calc(var(--base-size) * 1.4);
    font-weight: 600;
  }

  .header__dropdown,
  .header__submenu {
    display: flex;
    flex-direction: column;
    position: static;
  }

  .header__menu-btn {
    display: flex;
  }

  .header__sidebar-mobile-toggle {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem;
  }

  .header__menu-btn-mobile {
    background: none;
    border: none;
    height: 42px;
    width: 42px;
  }

  .header__menu-btn-mobile > img {
    height: 100%;
    width: 100%;
  }
}
