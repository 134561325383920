.faq__answer,
.faq__answer-wrapper,
.faq__question {
  transition: max-height 0.3s ease-out;
}

.faq__list-wrapper {
  display: flex;
  gap: 1.1rem;
  flex-direction: column;
}

.faq__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
}

.faq__row {
  display: flex;
  gap: 1rem;
}

@media (width < 540px) {
  .faq__row {
    flex-direction: column;
    gap: 1.6rem;
  }

  .faq__list-wrapper {
    gap: 1.6rem;
  }
}

.faq__list--left,
.faq__list--right {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.4rem;
}

.faq__item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.faq__question {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.faq__question > span {
  margin-top: 0.1rem;
}

.faq__answer-wrapper {
  max-height: 0;
  overflow: hidden;
}

.faq__item--open .faq__answer-wrapper {
  max-height: 1000px;
}

.faq__answer {
  padding-left: 2.4rem;
  padding-right: 2rem;
}

.faq__icon {
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 50%;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 1);
  display: flex;
  height: 32px;
  justify-content: center;
  margin-bottom: auto;
  margin-right: 8px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  position: relative;
  transition: transform 0.3s ease;
  width: 32px;
}

.faq__icon::before,
.faq__icon::after {
  background-color: var(--color-foreground);
  border-radius: 2px;
  content: '';
  height: 0.2rem;
  position: absolute;
  transition: transform 0.3s ease;
  width: 1rem;
}

.faq__icon::before {
  transform: rotate(0deg);
}

.faq__icon::after {
  transform: rotate(90deg);
}

.faq__item--open .faq__icon::before,
.faq__item--open .faq__icon::after {
  transform: rotate(-180deg);
}

.main-service[data-service-type='consulting'] .faq__icon::before,
.main-service[data-service-type='consulting'] .faq__icon::after {
  background: #00a59e !important;
}

.main-service[data-service-type='defensive'] .faq__icon::before,
.main-service[data-service-type='defensive'] .faq__icon::after {
  background: var(--color-blue) !important;
}

.main-service[data-service-type='offensive'] .faq__icon::before,
.main-service[data-service-type='offensive'] .faq__icon::after {
  background: var(--color-red) !important;
}

@media (width < 1024px) {
  .faq__list-wrapper {
    flex-direction: column;
  }
}
