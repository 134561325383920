.disclaimer {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.disclaimer__container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 0;
}

.disclaimer__text {
  align-items: center;
  color: var(--hero-background);
  display: flex;
  flex-wrap: wrap;
  font-size: calc(var(--base-size) * 1);
  line-height: calc(var(--base-size) * 1);
  font-weight: 600;
  gap: 0 0.3rem;
  justify-content: center;
  text-align: center;
}

@media (width < 540px) {
  .disclaimer__text {
    padding: 0 2.3rem;
  }
}

.disclaimer__text-cta {
  color: var(--hero-background);
  font-weight: 800;
  font-size: calc(var(--base-size) * 1);
}

.disclaimer__close {
  align-items: center;
  background: 0 0;
  border: none;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 0;
  width: 1rem;
}

.disclaimer__close img {
  filter: brightness(0);
  height: 100%;
  width: 100%;
  height: 14px;
}
