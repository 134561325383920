.logo-banner {
  display: flex;
  justify-content: center;
  max-height: 160px;
  min-height: 160px;
  position: relative;
}
@media (width < 1400px) {
  .logo-banner:nth-child(2) {
    margin-top: 0rem !important;
  }
}
.logo-banner__container {
  padding: 1rem;
}
.logo-banner__logos {
  display: flex;
  flex-wrap: wrap;
  gap: 1.3rem;
  justify-content: center;
}
.logo-banner__logo {
  height: 75px;
  max-width: 160px;
}
.logo-banner-swiper .swiper-wrapper {
  align-items: center;
  height: 100px;
  margin-bottom: 4rem;
  max-width: 1300px;
}
.logo-banner-swiper .swiper-slide img {
  border-radius: none;
  object-fit: contain;
}
.logo-banner-swiper {
  height: auto;
  width: 100%;
}
.logo-banner-swiper .swiper-slide {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.logo-banner .swiper-slide img {
  border-radius: 0 !important;
  object-fit: contain;
}
.logo-banner .swiper-slide {
  height: 80px;
}
.logo-banner .content-tabbed-content__arrows {
  display: none;
}
.logo-banner .swiper {
  align-items: center;
  display: flex;
  min-width: 100%;
}
.logo-banner .swiper-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
}
.logos .section-half-split__title {
  margin-bottom: 4rem;
  text-align: center;
}
.logos .section-half-split__title:before {
  display: none;
}
