.whatsapp-float {
  bottom: calc(var(--base-size) * 1.25);
  position: fixed;
  right: calc(var(--base-size) * 2.125);
  z-index: 100;
  transition: margin 0.3s ease;
}

.whatsapp-float.floating-transition {
  margin-bottom: 2.7rem;
}

.whatsapp-float__link {
  display: block;
  text-decoration: none;
}

.whatsapp-float__icon {
  color: var(--color-foreground);
  font-size: calc(var(--base-size) * 1.5);
  height: calc(var(--base-size) * 5);
  line-height: calc(var(--base-size) * 3.125);
  text-align: center;
  transition: all 0.3s ease;
  width: calc(var(--base-size) * 5);
}

.whatsapp-float__icon:hover {
  transform: scale(1.05);
}

.whatsapp-float__icon img {
  height: 100%;
  width: 100%;
}

.whatsapp-float__tooltip {
  background: var(--hero-background);
  border: 0.3rem solid white;
  border-radius: 1rem;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.15);
  font-size: calc(var(--base-size) * 0.8125);
  max-width: calc(100vw - 150px);
  opacity: 0;
  padding: 0.6rem 1rem;
  position: absolute;
  right: calc(var(--base-size) * 6.25);
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  visibility: hidden;
  width: calc(var(--base-size) * 23.75);
}

.whatsapp-float__link:hover .whatsapp-float__tooltip {
  opacity: 1;
  visibility: visible;
}

.whatsapp-float__tooltip-text {
  color: white;
  font-size: calc(var(--base-size) * 1);
  font-weight: 500;
}
