.job-openings__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.job-openings__item {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  overflow: hidden;
}

.job-openings {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.job-openings__position {
  font-size: calc(var(--base-size) * 2.8125);
  line-height: calc(var(--base-size) * 3.125);
}

.job-openings__title {
  font-size: calc(var(--base-size) * 2.8125);
  line-height: calc(var(--base-size) * 3.125);
  text-align: center;
}

.job-openings__container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 1rem;
}

.job-openings__details,
.job-openings__details--info {
  display: flex;
  gap: 0.6rem;
  flex-wrap: wrap;
}

.job-openings__details {
  flex: 1;
  flex-direction: column;
  min-width: 22.5rem;
  margin-bottom: 1rem;
}

.job-openings__item::after {
  background: #d2d2d1;
  bottom: 0rem;
  content: '';
  height: 0.3rem;
  left: 0;
  position: absolute;
  width: 100%;
}

.job-openings__apply-btn,
.job-openings__challenge-btn {
  align-self: baseline;
  height: auto;
  margin-top: auto;
}

.job-content-cta-section {
  background: #fbfcfd;
  border: 0.15rem solid #d2d8d6;
  border-radius: 0.4rem;
  padding: 1rem;
}

.job-content-cta-section-heading,
.job-content-cta-section-subheading {
  font-size: calc(var(--base-size) * 1.125);
  font-weight: 600;
  margin-bottom: 1rem;
}

.job-content-cta-section-heading {
  margin-top: 1.4rem;
  position: relative;
}

.job-content-cta-section-heading::before {
  background: var(--color-red);
  content: '';
  height: 0.15rem;
  left: 0;
  position: absolute;
  top: -1rem;
  transition: all 0.2s ease;
  width: 3.6rem;
}

.job-content-cta-section-description {
  margin-bottom: 1rem;
}

.job-content-cta-section-subheading {
  font-size: calc(var(--base-size) * 1);
}

.job-content-cta-section .btn.primary {
  align-self: baseline;
  margin: 0 auto;
  margin-top: 1rem;
}
