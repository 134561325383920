.privacy-policy__section {
  margin: 0;
}

.hero + .privacy-policy__section {
  margin-top: calc(var(--base-size) * 4);
}

.privacy-policy__section h2 {
  margin: calc(var(--base-size) * 3.2) 0 calc(var(--base-size) * 1) 0;
  font-size: calc(var(--base-size) * 1.6);
}

.privacy-policy__section h2:before {
  top: unset;
  bottom: calc(var(--base-size) * -0.2);
}

.privacy-policy__section h3 {
  margin: calc(var(--base-size) * 2.2) 0 calc(var(--base-size) * 0.8) 0;
}

.privacy-policy__section ul,
.privacy-policy__section ol {
  margin-left: calc(var(--base-size) * 2);
  margin-top: calc(var(--base-size) * 1);
  margin-bottom: calc(var(--base-size) * 1);
  font-size: calc(var(--base-size) * 1);
}

.privacy-policy__section ol li {
  list-style: decimal;
  margin: calc(var(--base-size) * 0.4) 0;
}

.privacy-policy__section ul li {
  list-style-type: disc;
  margin: calc(var(--base-size) * 0.4) 0;
}

.privacy-policy__section p > strong {
  margin: calc(var(--base-size) * 1.6) 0 calc(var(--base-size) * 1);
  display: block;
  font-size: calc(var(--base-size) * 1.2);
}
