.iti input.iti__tel-input,
.iti input.iti__tel-input[type='text'],
.iti input.iti__tel-input[type='tel'] {
  padding-left: 2.2rem !important;
}

.iti {
  width: 100%;
}

.iti__search-input {
  border: none !important;
  border-bottom: 1px solid #d8d8d8 !important;
}

.iti--inline-dropdown .iti__country-list {
  padding-left: .8rem;
}