.testimonials {
  display: flex;
  flex-direction: column;
}

.testimonials__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  padding: 1rem;
}

.testimonials__item {
  align-items: center;
  border-radius: calc(var(--border-radius) * 0.6);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

.testimonials__item-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
}

.testimonials__quote {
  font-size: calc(var(--base-size) * 1.6);
  font-weight: 400;
  line-height: calc(var(--base-size) * 2);
  margin-top: calc(var(--base-size) * 1);
  max-width: 1120px;
  position: relative;
  display: flex;
  text-align: left;
  gap: 1rem;
  text-wrap: auto;
}

.testimonials__quote__image--big {
  display: flex;
  height: 2.6rem !important;
  object-fit: contain !important;
  width: 2.6rem !important;
  top: -0.6rem;
  position: relative;
  margin-right: 1rem;
}

.testimonials__quote__image--small {
  display: flex;
  position: absolute;
  height: 2.6rem !important;
  object-fit: contain !important;
  width: 2.6rem !important;
  bottom: 0;
  right: 0;
  top: calc(100% - 1.4rem);
  position: relative;
  right: 0;
  margin-top: auto;
}

@media (width < 800px) {
  .testimonials__quote__image--small,
  .testimonials__quote__image--big {
    display: none;
  }
}

.testimonials__author {
  display: flex;
  flex-direction: column;
  min-height: 120px;
  gap: 0.3rem;
}

.testimonials__brand-logo {
  border-radius: 0 !important;
  margin: 0 auto;
  max-height: 6rem !important;
  max-width: 320px;
  min-height: 4.6rem !important;
  object-fit: contain !important;
}

.testimonials__author--name {
  font-size: calc(var(--base-size) * 1.4375);
  font-weight: 700;
  color: #6c6c6c;
}

.testimonials__author--title {
  font-size: calc(var(--base-size) * 1);
  font-weight: 700;
}

.testimonials__rating {
  display: flex;
  gap: 0.4rem;
}

.testimonials__rating img {
  width: 2rem !important;
}

.testimonials__slidewrapper {
  display: flex;
  gap: 0.8rem;
  justify-content: center;
  padding: 0.8rem;
}

.testimonials__slidewrapper--indicator img {
  height: 0.4rem;
  width: 0.4rem;
}

.testimonials__info {
  margin-top: 1.2rem;
}

.trusted__headline {
  font-size: calc(var(--base-size) * 2.8125);
  font-weight: 700;
  line-height: calc(var(--base-size) * 3);
}

.swiper-pagination-testemonial {
  display: flex;
  margin-left: 13.6rem;
  margin-top: 1.6rem;
}
