.button {
  background: gray;
  border: none;
  border-radius: calc(var(--border-radius) * 0.3);
  color: var(--color-foreground);
  cursor: pointer;
  font-size: calc(var(--base-size) * 1.25);
  font-weight: 700;
  padding: 0.65rem 2.4rem;
  white-space: nowrap;
}

.button a {
  font-size: calc(var(--base-size) * 1.25);
  line-height: calc(var(--base-size) * 1.4);
  font-weight: 700;
  color: var(--color-foreground);
}

.button--cta,
.button--primary {
  background: var(--color-red);
}

.button--primary {
  padding: 0.65rem 1.6rem;
}

.button--trinary {
  background: #0089d6;
}

.button--quaternary {
  background: var(--color-foreground);
  color: #0089d6;
}

.button--quaternary a {
  color: #0089d6;
}

.button-color-red {
  color: #1c1e26;
}

.button-color-red a {
  color: #1c1e26;
}

.button--quinary {
  background: #1c1e26;
}

.button--transparent {
  background: transparent;
  color: var(--color-foreground);
}

.button--transparent a {
  color: var(--color-foreground);
}

.main-service[data-service-type='consulting'] .button--primary {
  background: var(--color-green);
}

.main-service[data-service-type='defensive'] .button--primary {
  background: var(--color-blue);
}

.main-service[data-service-type='offensive'] .button--primary {
  background: var(--color-red);
}

.main-service[data-service-type='consulting'] .button--cta {
  background: var(--color-green);
}

.main-service[data-service-type='defensive'] .button--cta {
  background: var(--color-blue);
}

.main-service[data-service-type='offensive'] .button--cta {
  background: var(--color-red);
}
