.section-half-split:hover .section-half-split__title::before {
  width: 6rem;
}

.section-half-split:hover .section-half-split__image {
  scale: 1.02;
}

.section-half-split-header {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: calc(var(--base-size) * 3.75);
}

.section-half-split-header__title {
  font-size: calc(var(--base-size) * 2.8125);
  font-weight: 700;
  line-height: calc(var(--base-size) * 2.8125);
  text-align: center;
}

.section-half-split-header__subtitle {
  color: #5b5b5b;
  font-size: calc(var(--base-size) * 1.6875);
  font-weight: 500;
  line-height: calc(var(--base-size) * 1.6875);
  text-align: center;
}

.section-half-split__container {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.section-half-split__container > div {
  min-width: 22.6rem;
}

.section-half-split__image-wrapper {
  border-radius: calc(var(--border-radius) * 0.6);
  display: flex;
  flex: 1;
  justify-content: center;
  order: 1;
  overflow: hidden;
  position: relative;
}

.section-half-split.flip .section-half-split__image-wrapper {
  order: 2;
}

.section-half-split__image-wrapper .swiper {
  height: 100% !important;
}

.section-half-split__image {
  display: flex;
  height: 100%;
  object-fit: cover;
  transition: scale 0.3s;
  width: 100%;
}

.section-half-split__content-wrapper,
.section-half-split__video-wrapper {
  flex: 1;
  order: 2;
}

.section-half-split__video-wrapper {
  border-radius: calc(var(--border-radius) * 0.6);
  overflow: hidden;
}

.section-half-split__video-wrapper > iframe {
  height: 100%;
  width: 100%;
}

.section-half-split.flip .section-half-split__content-wrapper {
  order: 1;
}

.section-half-split.flip .section-half-split__content-wrapper:first-child {
  order: 2;
}

.section-half-split.flip .section-half-split__content-wrapper:last-child {
  order: 1;
}

.section-half-split.flip .section-half-split__video-wrapper {
  order: 2;
}

.section-half-split__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}

.section-half-split__content .button {
  align-self: baseline;
  margin-top: auto;
}

.section-half-split__title {
  display: flex;
  flex-direction: column;
  font-size: calc(var(--base-size) * 2.6);
  line-height: calc(var(--base-size) * 3);
  position: relative;
}

@media (width < 1520px) {
  .section-half-split__title {
    font-size: calc(var(--base-size) * 2.25);
    line-height: calc(var(--base-size) * 2.5);
  }
}

.section-half-split__title::before {
  background: var(--color-red);
  content: '';
  height: 0.25rem;
  left: 0;
  position: absolute;
  transition: all 0.2s ease;
  width: 3.6rem;
  bottom: -0.75rem;
}

.section-half-split__date {
  font-size: calc(var(--base-size) * 1.875);
  text-align: end;
}

@media (width < 1024px) {
  .section-half-split__container {
    flex-direction: column;
  }

  .section-half-split__container > div {
    min-width: 16.25rem;
  }

  .section-half-split.flip .section-half-split__image-wrapper {
    order: 1;
  }
}

@media (width < 887px) {
  .section-half-split__container {
    padding: 0rem;
  }

  .section-half-split__container > div {
    min-width: 12.5rem;
  }

  .section-half-split__image-wrapper .swiper {
    height: unset !important;
  }
}

@media (width < 480px) {
  .section-half-split__container > div {
    min-width: 6.25rem;
  }
}
