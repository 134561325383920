.cookie-popup {
  background-color: #2e2e2e;
  border: 0.3rem solid white;
  border-radius: 1rem;
  bottom: 1rem;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.15);
  color: var(--color-foreground);
  left: 1rem;
  margin-right: 1rem;
  max-width: 700px;
  padding: 1rem;
  position: fixed;
  z-index: 1000;
}

.cookie-popup__content {
  align-items: center;
  display: flex;
  text-align: center;
}

.cookie-popup__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.cookie-popup__icon-image {
  height: 160px;
  width: 170px;
}

.cookie-popup__title {
  color: white;
  font-size: calc(var(--base-size) * 2);
  font-weight: bold;
  line-height: calc(var(--base-size) * 2.2);
  margin: 10px 0;
}

.cookie-popup__description {
  color: white;
  font-size: calc(var(--base-size) * 1);
  line-height: 1.2;
  margin-bottom: 15px;
}

.cookie-popup__actions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.cookie-popup__button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  font-size: calc(var(--base-size) * 0.875);
  padding: 10px 15px;
  transition: background-color 0.3s;
}

.cookie-popup__button--accept {
  background: white;
  color: var(--hero-background);
  font-weight: 800;
  margin: 0 auto;
  max-width: 160px;
}

.cookie-popup__button--close {
  background: none;
  color: var(--color-foreground);
  position: absolute;
  right: 0;
  top: 0;
}

.cookie-popup__icon {
  width: 100%;
}

@media (width < 480px) {
  .cookie-popup__content {
    flex-wrap: wrap;
  }
}

@media (width < 1360px) {
  .cookie-popup__content {
    flex-direction: column;
  }

  .cookie-popup {
    /* max-width: 35vw; */
    max-width: 370px;
  }

  .cookie-popup__icon-image,
  .cookie-popup__icon {
    width: 100px;
    height: 100px;
  }

  .cookie-popup__title {
    font-size: calc(var(--base-size) * 1.6);
  }

  .cookie-popup__description {
    font-size: calc(var(--base-size) * 1);
  }
}
