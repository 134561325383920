.umbraco-forms-JobOfferFormTheme {
  width: 100%;
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-field-wrapper input,
.umbraco-forms-JobOfferFormTheme .umbraco-forms-field-wrapper textarea {
  background: #fbfcfd;
  border: calc(var(--base-size) * 0.15) solid #d2d8d6;
  border-radius: calc(var(--base-size) * 0.4);
  font-size: calc(var(--base-size) * 0.9);
  font-weight: 600;
  padding: calc(var(--base-size) * 1);
  padding-top: calc(var(--base-size) * 1.2);
  resize: none;
  width: 100%;
  font-family: 'LiniK Sans';
  font-weight: 500;
}

.umbraco-forms-JobOfferFormTheme
  .umbraco-forms-field-wrapper
  input::placeholder,
.umbraco-forms-JobOfferFormTheme
  .umbraco-forms-field-wrapper
  textarea::placeholder,
.umbraco-forms-JobOfferFormTheme input[type='file']::after {
  color: #a2adab;
  font-size: calc(var(--base-size) * 1);
  font-weight: 500;
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-label {
  font-size: calc(var(--base-size) * 0.9);
  font-weight: 500;
  margin-left: calc(var(--base-size) * 1.1);
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-field-wrapper {
  display: flex;
  height: calc(var(--base-size) * 3.4);
  position: relative;
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-size) * 1);
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-hidden {
  display: none;
}

.umbraco-forms-JobOfferFormTheme input[type='file'] {
  border-radius: calc(var(--base-size) * 0.3);
  inset: 0;
  z-index: 1;
  color: #a2adab;
  font-weight: 500;
  font-size: calc(var(--base-size) * 1);
  padding-top: 1rem;
}

.umbraco-forms-JobOfferFormTheme input[type='file']::file-selector-button {
  display: none;
}

.umbraco-forms-JobOfferFormTheme input[type='file']::after {
  content: 'Upload CV';
  display: none;
  left: calc(var(--base-size) * 1);
  position: absolute;
  top: calc(var(--base-size) * 1.2);
}

input[type='file']::-webkit-file-upload-button {
  display: none;
  visibility: hidden;
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-page {
  display: flex;
  flex-direction: column;
}

.umbraco-forms-JobOfferFormTheme
  .umbraco-forms-field.longanswer
  .umbraco-forms-field-wrapper {
  height: calc(var(--base-size) * 12);
}

.umbraco-forms-JobOfferFormTheme .umbraco-forms-navigation {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: calc(var(--base-size) * 2);
}

.umbraco-forms-JobOfferFormTheme .field-validation-error {
  display: none;
}
